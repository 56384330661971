import { OnInit } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { of, concat } from 'rxjs';
import { map } from 'rxjs/operators';
var ErrorMessageComponent = /** @class */ (function () {
    function ErrorMessageComponent() {
        this.config = {
            pattern: "Formato incorreto",
            max: "O valor ultrapassa o m\u00E1ximo permitido",
            min: "O valor \u00E9 menor que o m\u00EDnimo permitido",
            maxlength: "Limite m\u00E1ximo de caracteres atingido",
            minlength: "O campo n\u00E3o possui o valor m\u00EDnimo de caracteres",
            email: "E-mail inv\u00E1lido",
            required: "Campo obrigat\u00F3rio",
            validCPF: "CPF informado inv\u00E1lido",
            password: "A senha deve ser entre 6 e 12 caracteres contendo letras e n\u00FAmeros",
            validPasswordNumber: "A senha deve conter n\u00FAmeros",
            validPasswordWord: "A senha deve conter letras",
            validUrl: "Formato da URL inv\u00E1lido",
            validHour: "Hora inv\u00E1lida",
            validIntervalHour: "Intervalo inv\u00E1lido",
        };
    }
    Object.defineProperty(ErrorMessageComponent.prototype, "messages", {
        set: function (newConfig) {
            Object.assign(this.config, newConfig);
        },
        enumerable: true,
        configurable: true
    });
    ErrorMessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.ngModel) {
            var formSubmit$ = this.ngModel.formDirective.ngSubmit.pipe(map(function () {
                _this.hasSubmitted = true;
                return false;
            }));
            this.controlValue$ = concat(this.control.valueChanges, of(''), formSubmit$);
        }
        else if (this.control) {
            this.load();
            this.controlValue$ = concat(this.control.valueChanges, of(''));
        }
        this.controlValue$.subscribe(function () { return _this.setVisible(); });
    };
    ErrorMessageComponent.prototype.setVisible = function () {
        if (!this.control) {
            return;
        }
        if (this.control.invalid && (this.control.dirty || this.hasSubmitted)) {
            this.load();
        }
        else {
            this.error = '';
        }
    };
    ErrorMessageComponent.prototype.load = function () {
        var _this = this;
        if (this.control.errors) {
            this.error = Object.keys(this.control.errors)
                .filter(function (keyError) { return !!_this.config[keyError]; })
                .map(function (keyError) { return _this.config[keyError]; })[0];
        }
    };
    return ErrorMessageComponent;
}());
export { ErrorMessageComponent };
