export const enum AlertMessageType {
  S = 'S',
  SUCCESS = S,
  E = 'E',
  ERROR = E,
  W = 'W',
  WARNING = W,
  I = 'I',
  INFO = I,
  D = 'D',
  DEBUG = D,
}

export enum MessageMap {
  // Errors
  CAMPOS_EM_VERMELHO = 'Verifique os campos marcados em vermelho.',
  DUPLICATE_SOCIAL_MEDIA = 'Há redes sociais duplicadas.',
  HOUR_EMPTY_FIELD = 'Informe no mínimo uma faixa de horário ou marque a opção "Fechado" para o dia "{{0}}".',
  OUT_OF_HOUR_RANGE = 'Verifique as faixas de horário informadas.',
  INVALID_SUBTITLE = 'Existem legendas não inseridas ou que ultrapassaram o limite máximo de caracteres',
  ERRO_PROCESSAMENTO = 'Erro de processamento: {{0}}',
  CONFLICTING_PROMOTION_PERIOD = 'O intervalo das datas conflita com o de outras promoções!',
  CONFLICTING_PROMOTION_LAST_DATE = 'Data inicial deve ser posterior à data final da última promoção',
  SEM_CONEXAO_INTERNET = 'Não foi possível conectar. Verifique sua conexão e tente novamente.',
  NO_CHANGES_TO_SAVE = 'Nenhuma alteração para salvar!',
  UNSAVED_CHANGES = 'Existem alterações que ainda não foram salvas e podem ser perdidas. Deseja realmente sair desta página?',
  REQUIRED_ADHESION_TERM = 'O termo de adesão é obrigatório!',
  USER_NOT_ENABLED = 'Usuário inexistente ou sem academias vinculadas.',
  USER_NOT_ENABLED2 = 'Dados de login incorretos ou usuário sem academias vinculadas.',
  USER_REGISTER_ERROR = 'Aconteu um erro ao cadastrar o usuário, verifique os dados inseridos e tente novamente.',

  // Successes
  SALVO_COM_SUCESSO = 'Salvo com sucesso!',
}

// tslint:disable-next-line: no-namespace
export namespace MessageMap {
  export function SUCCESSFULLY_CREATED(female: boolean = false): string {
    return female
      ? '{{0}} criada com sucesso!'
      : '{{0}} criado com sucesso!';
  }
  export function SUCCESSFULLY_EDITED(female: boolean = false): string {
    return female
      ? '{{0}} editada com sucesso!'
      : '{{0}} editado com sucesso!';
  }
  export function SUCCESSFULLY_DELETED(female: boolean = false): string {
    return female
      ? '{{0}} excluída com sucesso!'
      : '{{0}} excluído com sucesso!';
  }
  export function SUCCESSFULLY_UPDATED(female: boolean = false): string {
    return female
      ? '{{0}} atualizada com sucesso!'
      : '{{0}} atualizado com sucesso!';
  }
}

export class AlertMessage {
  constructor(
    public type: AlertMessageType,
    public msg: string,
  ) { }

  static success(message: string) {
    return new AlertMessage(AlertMessageType.S, message);
  }

  static error(message: string) {
    return new AlertMessage(AlertMessageType.E, message);
  }

  static warning(message: string) {
    return new AlertMessage(AlertMessageType.W, message);
  }

  static info(message: string) {
    return new AlertMessage(AlertMessageType.I, message);
  }

  static debug(message: string) {
    return new AlertMessage(AlertMessageType.D, message);
  }
}

export enum ToastType {
  E = 'toast-error',
  I = 'toast-info',
  S =  'toast-success',
  W = 'toast-warning',
}
